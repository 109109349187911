<template>
  <div>
    <div v-if="field.type == 'text' || field.type == 'email'" class="field">
      <label class="label">
        {{field.label}}
        <span v-if="this.isRequired">*</span>
      </label>
      <div class="control has-icons-right">
        <input
          :name="field.name"
          :placeholder="field.label"
          :required="isRequired"
          class="input"
          :type="field.type"
          v-on:change="valueChanged"
        />
        <span class="icon is-small is-right" v-show="!validator.$invalid">
          <i class="fas fa-check"></i>
        </span>
        <span class="icon is-small is-right" v-show="submit && validator.$invalid">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </div>
      <p class="help is-danger" v-if="submit && validator.required === false">This field is required</p>
      <p class="help is-danger" v-if="submit && validator.phone === false">Invalid phone number</p>
      <p class="help is-danger" v-if="submit && validator.mobile === false">Invalid mobile number</p>
      <p class="help is-danger" v-if="submit && validator.email === false">Invalid email</p>
    </div>
    <!-- <div v-if="field.type == 'phone' || field.type == 'email'" class="field">
      <label class="label">
        {{field.label}}
        <span v-if="this.isRequired">*</span>
      </label>
      <div class="control has-icons-right VuePhoneNumberInput">
        <VuePhoneNumberInput 
          v-model="inputValue"
          default-country-code="GB"
          :no-country-selector="true"

          :name="field.name"
          :placeholder="field.label"
          :required="isRequired"
          class="input"
          :type="field.type"
          v-on:input="valueChanged2"
        />
        <span class="icon is-small is-right" v-show="!validator.$invalid">
          <i class="fas fa-check"></i>
        </span>
        <span class="icon is-small is-right" v-show="submit && validator.$invalid">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </div>
      <p class="help is-danger" v-if="submit && validator.required === false">This field is required</p>
      <p class="help is-danger" v-if="submit && validator.phone === false">Invalid phone number</p>
      <p class="help is-danger" v-if="submit && validator.mobile === false">Invalid mobile number</p>
      <p class="help is-danger" v-if="submit && validator.email === false">Invalid email</p>
    </div> -->
    <div v-if="field.type == 'select'" class="field">
      <label class="label">
        {{field.label}}
        <span v-if="this.isRequired">*</span>
      </label>
      <div class="select">
        <select :name="field.name" :required="isRequired" v-on:change="valueChanged">
          <option v-for="(value, key) in field.options" :key="key" :value="key">{{value}}</option>
        </select>
      </div>
      <p class="help is-danger" v-if="submit && validator.$invalid">This field is required</p>
    </div>
    <div v-if="field.type == 'textarea'" class="field">
      <label class="label">
        {{field.label}}
        <span v-if="this.isRequired">*</span>
      </label>
      <div class="control has-icons-right">
        <textarea :name="field.name" :required="isRequired"  v-on:change="valueChanged" :placeholder="field.label" class="textarea"></textarea>
        <span class="icon is-small is-right" v-show="!validator.$invalid">
          <i class="fas fa-check"></i>
        </span>
        <span class="icon is-small is-right" v-show="submit && validator.$invalid">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
      </div>
      <p class="help is-danger" v-if="submit && validator.$invalid">This field is required</p>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
// import VuePhoneNumberInput from 'vue-phone-number-input';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';


export default {
  // components: {
  //   VuePhoneNumberInput
  // },
  props: {
    value: {
      type: String
    },
    field: {
      type: Object,
      required: true
    },
    submit: {
      type: Boolean,
      required: true
    },
    validator: {
      type: Object
    }
  },
  data() {
    return {
      inputValue:""
    };
  },
  mounted() {
    this.inputValue = this.value;
    if(this.field.type == "hidden"){
      this.$emit("input", this.field.value); // For v-model
    }
  },
  updated() {},
  methods: {
    valueChanged(e) {
      this.$emit("input", e.target.value); // For v-model
    },
    valueChanged2(e) {
      this.$emit("input", this.inputValue); // For v-model
    }
  },
  computed: {
    isRequired() {
      return !(!this.field.hasOwnProperty("required") || !this.field.required);
    }
  }
};
</script>

<style lang="less">
.field {
  margin-bottom: 0.75rem;
}

.select {
  width: 100%;
  select {
    width: 100%;
  }
}

// .VuePhoneNumberInput > div > div > div > input {
//   background-color: transparent !important;
//   border: none  !important;
//   caret-color: transparent  !important;
//   box-shadow: none !important;
//   padding-left: 0px !important;
//   font-style: italic;
// }
</style>
