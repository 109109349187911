<template>
  <div
    ref="containerMessageDisplay"
    :style="{background: colors.message.messagesDisplay.bg}"
    class="container-message-display"
    @scroll="updateScrollState"
  >
    <perfect-scrollbar id="perfectScrollbar">
      <div v-if="loading" class="loader">
        <div class="message-loading"></div>
      </div>
      <message-render
        v-for="message in messages2"
        :key="message.id"
        :message="message"
      />
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MessageRender from "./MessageRender.vue";

export default {
  components: {
    MessageRender,
  },
  props: {
    colors: {
      type: Object,
      required: true
    },
    asyncMode: {
      type: Boolean,
      required: false,
      default: false
    },
    loadMoreMessages: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      updateScroll: true,
      lastMessage: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["getParticipantById", "messages", "myself"]),
    messages2() {
      let messages2;
      if(this.limitTo){
        messages2= this.messages.slice(this.messages.length - this.limitTo);
      }else{
        messages2 = this.messages;
      }
      return messages2.map((message) => {
        if (
          typeof message.sender_name === "undefined" &&
          this.getParticipantById(message.participantId) !== null
        ) {
          let userData = this.getParticipantById(message.participantId);
          if (typeof userData !== "undefined") {
            message.sender_name = userData.name;
            message.sender_image = userData.image;
          } else {
            console.log("NO USER FOUND BY ID: ", message.participantId);
          }
        }
        if (
          typeof message.sender_type === "undefined" ||
          message.sender_type === null
        ) {
          message.sender_type = "user";
          if (
            this.getParticipantById(message.participantId) !== null &&
            this.getParticipantById(message.participantId).type === "agent"
          ) {
            message.sender_type = "agent";
          }
        }
        if (
          typeof message.message_type === "undefined" ||
          message.message_type === null
        ) {
          message.message_type = message.sender_type;
        }
        if (message.content.indexOf("!") === 0) {
          message.message_type = "whisper";
          message.content = message.content.substring(1);
        }
        return message;
      });
    },
  },
  mounted() {
    this.goToBottom();
    this.$refs.containerMessageDisplay.dispatchEvent(new CustomEvent("scroll"));
  },
  updated() {
    if (
      this.messages.length &&
      this.messages[this.messages.length - 1] !== this.lastMessage &&
      this.updateScroll
    ) {
      //   this.goToBottom();
      if (this.messages.length) {
        this.lastMessage = this.messages[this.messages.length - 1];
      }
    }
    this.goToBottom();
  },
  methods: {
    ...mapMutations(["setMessages"]),
    updateScrollState({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.updateScroll = scrollTop + clientHeight >= scrollHeight;

      if (typeof this.loadMoreMessages === "function" && scrollTop < 20) {
        this.loading = true;
        this.loadMoreMessages(messages => {
          if (Array.isArray(messages) && messages.length > 0) {
            this.setMessages([...messages, ...this.messages]);
          }
          this.loading = false;
        });
      }
    },
    goToBottom() {
      // let scrollDiv = this.$refs.containerMessageDisplay;
      // scrollDiv.scrollTop = scrollDiv.scrollHeight;

      let scrollDiv = document.querySelector("#perfectScrollbar");
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }
  }
};
</script>

<style lang="less">
.quick-chat-container .container-message-display {
  flex: 1;
  min-height: 0px;
  max-height: 100%;
  margin: 0 20px;
}

.ps {
  height: 100%;
}
</style>
