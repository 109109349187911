<template>
  <div style="height: calc(100% - 62px)">
    <div v-if="oohFormSettings.iframe" style="height: 100%">
      <iframe :src="oohFormSettings.iframe" style="width: 100%; height: 100%" />
    </div>
    <div
      v-if="!oohFormSettings.iframe && oohFormSettings.oohform"
      style="height: 100%"
    >
      <perfect-scrollbar tag="section" class="section ooh-form" v-if="!formSentSuccess">
        <!-- {{ oohFormSettings.oohform.form_action }} -->
        <p class="has-text-centered welcome-message">
          {{ oohFormSettings.msg }}
        </p>
        <AdditionalField
          v-for="(field, index) in oohFormSettings.oohform.fields"
          v-model="additionalFields[field.name]"
          :validator="$v.additionalFields[field.name]"
          :key="index"
          :field="field"
          :submit="submit"
        />
        <div class="field submit-button">
          <div class="control">
            <button
              type="submit"
              :disabled="disableSubmit"
              class="button is-success is-fullwidth"
              v-on:click="sendForm"
            >
              Submit
            </button>
          </div>
        </div>
      </perfect-scrollbar>
      <div v-if="formSentSuccess">

        
        <p class="has-text-centered welcome-message">
          <i style='color: green; font-size:120px;' class="fas fa-check-circle"></i>
        </p>
        <p class="has-text-centered welcome-message">
          {{ this.oohFormSettings.oohform.form_submitted_message }}
        </p>
      </div>
    </div>
    <div v-if="!oohFormSettings.iframe && !oohFormSettings.oohform">
      <div class="container">
        <p class="has-text-centered welcome-message">
          {{ oohFormSettings.msg }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "query-string";
import axios from "axios";
import AdditionalField from "./AdditionalField.vue";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "OohForm",
  components: {
    AdditionalField,
  },
  data: function () {
    return {
      additionalFields: {},
      submit: false,
      disableSubmit: false,
      formSentSuccess: false,
    };
  },
  props: {
    oohFormSettings: {
      type: Object,
      required: true,
      default: function () {
        return {
          iframe: false,
          msg: "Sorry, webchat is not currently available ",
        };
      },
    },
    windowSettings: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    sendForm: function (event) {
      if (event) {
        event.preventDefault();
      }
      this.submit = true;
      console.log(this.$v);
      if (this.$v.$invalid) {
        return;
      }
      this.disableSubmit = true;
      this.$emit("showOverlay", this.oohFormSettings.oohform.form_submitting_message, 20000);
      axios
        .post(
          this.oohFormSettings.oohform.form_action,
          qs.stringify({ data: qs.stringify(this.additionalFields) }),
          {
            timeout: 10000,
          }
        )
        .then((response) => {
          this.disableSubmit = false;
          console.log(response);
          if (response.data !== "SUCCESS") {
            throw new Error("Bad response");
          }
          this.$emit("hideOverlay");
          this.formSentSuccess = true;
        })
        .catch((e) => {
          this.disableSubmit = false;
          this.$emit("errorChat", "Connection error. Please try again.");
          console.error(e);
        });
    },
    ddPhone(val) {
      console.log('ddPhone', this.additionalFields, val);
      if (typeof val === 'undefined' || val.length < 8 || val.length > 11 || val.indexOf('0') !== 0) {
        return false;
      }
      return true;
    },
    ddMobile(val) {
      console.log('ddPhone', this.additionalFields, val);
      if (typeof val === 'undefined' ||val.length != 11 || val.indexOf('07') !== 0) {
        return false;
      }
      return true;
    }
  },
  computed: {
    rules() {
      var rules = {};
      rules["additionalFields"] = {};
      if (
        this.oohFormSettings.oohform.fields != undefined &&
        this.oohFormSettings.oohform.fields.length
      ) {
        this.oohFormSettings.oohform.fields.forEach((element) => {
          rules["additionalFields"][element.name] = {};
          if (
            element.hasOwnProperty("required") != undefined &&
            element.required
          ) {
            rules["additionalFields"][element.name]["required"] = required;
          }

          if (
            element.hasOwnProperty("validate") != undefined &&
            element.validate
          ) {
            rules["additionalFields"][element.name]["minLength"] = minLength(2);

            switch (element.validate) {
              case "email":
                rules["additionalFields"][element.name]["email"] = email;
                break;
              case "phone":
                rules["additionalFields"][element.name]["phone"] = this.ddPhone;
                break;
              case "mobile":
                rules["additionalFields"][element.name]["mobile"] = this.ddMobile;
                break;
            }
            console.log(element.validate);
          }
        });
      }
      console.log(rules);
      return rules;
    },
  },
  validations() {
    return this.rules;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ooh-form {
  padding: 0 1.5rem;
}
</style>