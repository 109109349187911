import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex);

export default () => {
    return new Vuex.Store({
        state: {
            messages: [],
            myself: {},
            participants: [],
            chatTitle: '',
            placeholder: ''
        },
        mutations: {
            newMessage: (state, message) => {
                // console.log(message);
                message.timestamp = message.timestamp.toISOString();
                state.messages = state.messages.filter(object => object.id != message.id);
                if (!message.typing) {
                    // Remove typing indicator on new message
                    state.messages = state.messages.filter(object => {
                        return (object.typing == false || object.participantId != message.participantId)
                    });
                }
                state.messages = [...state.messages, message];
                state.messages.sort((a, b) => (a.timestamp >= b.timestamp) ? ((a.timestamp == b.timestamp) ? 0 : 1) : -1);
            },
            deleteMessage: (state, id) => {
                state.messages = state.messages.filter(object => object.id != id);
            },
            clearMessages: (state) => {
                state.messages = [];
            },
            setParticipants: (state, participants) => {
                state.participants = participants;
            },
            setMyself: (state, myself) => {
                state.myself = myself;
            },
            setMessages: (state, messages) => {
                messages.map(message => {
                    message.timestamp = moment(message.timestamp).toISOString();
                });
                state.messages = messages;
            },
            setChatTitle: (state, title) => {
                state.chatTitle = title;
            },
            setPlaceholder: (state, placeholder) => {
                state.placeholder = placeholder;
            }
        },
        actions: {},
        getters: {
            getParticipantById: (state) => (id) => {
                let curr_participant = null;
                state.participants.forEach(participant => {
                    if (participant.id == id) {
                        curr_participant = participant;
                    }
                });

                return curr_participant;
            },
            messages: (state) => {
                let messages = [];
                let participantMsgNum = {};
                state.messages.forEach(message => {
                    var participantId = message.participantId;
                    if (participantMsgNum[participantId] == undefined) {
                        participantMsgNum[participantId] = 1
                    } else {
                        participantMsgNum[participantId]++;
                    }
                    let newMessage = { ...message };
                    newMessage.participantMessageNumber = participantMsgNum[participantId];
                    newMessage.timestamp = moment(newMessage.timestamp);
                    messages.push(newMessage);
                });
                // console.log(messages);
                return messages;
            },
            myself: (state) => {
                return state.myself;
            }
        }
    })
}
