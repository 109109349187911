<template>
  <div
    class="quick-chat-container"
    :style="{'border-bottom-left-radius': borderStyle.bottomLeft, 'border-bottom-right-radius': borderStyle.bottomRight, 'border-top-right-radius': borderStyle.topRight, 'border-top-left-radius': borderStyle.topLeft}"
  >
    <MessageDisplay
      :colors="colors"
      :async-mode="asyncMode"
      :load-more-messages="loadMoreMessages"
    />
    <MessageManager
      v-if="inputVisible"
      :window-settings="windowSettings"
      :on-type="onType"
      :on-message-submit="onMessageSubmit"
      :colors="colors"
      :border-style="borderStyle"
      :submit-icon-size="submitIconSize"
    />
  </div>
</template>

<script>
import MessageDisplay from "./MessageDisplay.vue";
import MessageManager from "./MessageManager.vue";
import { mapMutations } from "vuex";

export default {
  name: "chat",
  components: {
    MessageDisplay,
    MessageManager
  },
  props: {
    chatSettings: {
      type: Object,
      required: false
    },
    windowSettings: {
      type: Object,
      required: true
    },
    participants: {
      type: Array,
      required: true
    },
    messages: {
      type: Array,
      required: true
    },
    myself: {
      type: Object,
      required: true
    },
    chatTitle: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: "type your message here"
    },
    onType: {
      type: Function,
      required: false,
      default: () => false
    },
    onMessageSubmit: {
      type: Function,
      required: false,
      default: () => false
    },
    colors: {
      type: Object,
      required: true
    },
    borderStyle: {
      type: Object,
      required: false,
      default: () => {
        return {
          topLeft: "10px",
          topRight: "10px",
          bottomLeft: "10px",
          bottomRight: "10px"
        };
      }
    },
    submitIconSize: {
      type: String,
      required: false,
      default: "15px"
    },
    asyncMode: {
      type: Boolean,
      required: false,
      default: false
    },
    inputVisible: {
      type: Boolean,
      required: false,
      default: true
    },
    loadMoreMessages: {
      type: Function,
      required: false,
      default: null
    }
  },
  watch: {
    chatTitle() {
      this.setChatTitle(this.chatTitle);
    },
    messages() {
      this.setMessages(this.messages);
    }
  },
  created() {
    this.setParticipants(this.participants);
    this.setMyself(this.myself);
    this.setMessages(this.messages);
    this.setPlaceholder(this.placeholder);
    this.setChatTitle(this.chatTitle);
  },
  methods: {
    ...mapMutations([
      "setParticipants",
      "setMyself",
      "setMessages",
      "setPlaceholder",
      "setChatTitle"
    ])
  }
};
</script>

<style lang="less">
.quick-chat-container {
  display: flex;
  width: 100%;
  height: 100%;
  background: #f0eeee;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  padding-bottom: 62px;
}
</style>
