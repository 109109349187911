<template>
  <!-- <section class="section login-form"> -->
  <perfect-scrollbar tag="section" class="section login-form">
    <div class="container">
      <p class="has-text-centered welcome-message">{{loginFormSettings.loginMsg}}</p>

      <form method="post">
        <div class="field">
          <label class="label">
            {{loginFormSettings.yourName}}
            <span v-if="windowSettings.name_required">*</span>
          </label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              name="name"
              type="text"
              :placeholder="loginFormSettings.yourName"
              v-model.trim="userName"
            />
            <span class="icon is-small is-left">
              <i class="far fa-user"></i>
            </span>
            <span class="icon is-small is-right" v-show="!userNameInvalid">
              <i class="fas fa-check"></i>
            </span>
            <span class="icon is-small is-right" v-show="submit && userNameInvalid">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <p class="help is-danger" v-if="submit && userNameInvalid">Please enter your name</p>
        </div>
        <div class="field">
          <label class="label">
            {{loginFormSettings.yourEmail}}
            <span v-if="windowSettings.email_required">*</span>
          </label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              name="email"
              type="email"
              :placeholder="loginFormSettings.yourEmail"
              value
              v-model="userEmail"
            />
            <span class="icon is-small is-left">
              <i class="far fa-envelope"></i>
            </span>
            <span class="icon is-small is-right" v-show="!emailInvalid">
              <i class="fas fa-check"></i>
            </span>
            <span class="icon is-small is-right" v-show="submit && emailInvalid">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <p class="help is-danger" v-if="submit && emailInvalid">This email is invalid</p>
        </div>
        <div class="field" v-if="windowSettings.phone_required">
          <label class="label">
            {{loginFormSettings.yourPhone}}
            <span>*</span>
          </label>
          <div class="control has-icons-left has-icons-right">
            <input
              class="input"
              name="phone"
              type="tel"
              :placeholder="loginFormSettings.yourPhone"
              value
              v-model="userPhone"
            />
            <span class="icon is-small is-left">
              <i class="fas fa-phone"></i>
            </span>
            <span class="icon is-small is-right" v-show="!phoneInvalid">
              <i class="fas fa-check"></i>
            </span>
            <span class="icon is-small is-right" v-show="submit && phoneInvalid">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </div>
          <p class="help is-danger" v-if="submit && phoneInvalid">This phone is invalid</p>
        </div>
        <AdditionalField
          v-for="(field, index) in loginFormSettings.additionalFields"
          v-model="additionalFields[field.name]"
          :validator="$v.additionalFields[field.name]"
          :key="index"
          :field="field"
          :submit="submit"
        />
        <div class='centerCaptcha'>
          <vue-hcaptcha ref="captcha" v-if="useCaptcha && windowSettings.captcha_sitekey" :sitekey="windowSettings.captcha_sitekey" @verify="captchaSolved" @expired="captchaInvalid" @reset="captchaInvalid"></vue-hcaptcha>
        </div>
        <div class="field submit-button">
          <div class="control">
            <button
              type="submit"
              :disabled="checkDisableButton"
              class="button is-success is-fullwidth"
              v-on:click="beginChat"
            >{{loginFormSettings.startChat}}</button>
          </div>
        </div>
      </form>
    </div>
  </perfect-scrollbar>
  <!-- </section> -->
</template>

<script>
import axios from "axios";
import AdditionalField from "./AdditionalField.vue";
import { required, minLength, email } from "vuelidate/lib/validators";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

// match between 7 and 13 digits mixed with any number of non-digit characters
const phoneNumber = (value) => /[^\d]*(\d[^\d]*){7,13}/.test(value)

export default {
  name: "LoginForm",
  components: {
    AdditionalField,
    VueHcaptcha
  },
  props: {
    loginFormSettings: {
      type: Object,
      required: true,
      default: function() {
        return {
          loginMsg: "Enter your details to proceed",
          yourName: "Your name",
          yourEmail: "E-mail",
          yourPhone: "Phone Number",
          startChat: "Click to begin chat",
          additionalFields: {}
        };
      }
    },
    windowSettings: {
      type: Object,
      required: true,
      default: function() {
        return {};
      }
    },
  },
  methods: {
    captchaSolved: function(token, eKey) {
      this.captchaToken = token;
      this.catpchaEKey = eKey;
    },
    captchaInvalid: function(){
      this.captchaToken = "";
      this.catpchaEKey = "";
    },
    beginChat: function(event) {
      if (event) {
        event.preventDefault();
      }
      this.submit = true;
      // console.log(this.$v);
      if (this.$v.$invalid) {
        return;
      }
      this.disableSubmit = true
      this.$emit("showOverlay", this.loginFormSettings.connectingMsg);
      axios
        .post(
          this.windowSettings.token_url,
          {
            customer_name: this.userName,
            customer_email: this.userEmail,
            customer_phone: this.userPhone,
            additional_fields: this.additionalFields,
            chat_token: this.windowSettings.chat_token,
            captcha_token: this.captchaToken
          },
          {
            timeout: 10000
          }
        )
        .then(response => {
          // console.log(response);
          if (typeof response.data.error !== "undefined" && response.data.error === "_InvalidCatchaToken"){
            this.windowSettings.captcha_sitekey = response.data.sitekey;
            if (!this.useCaptcha){
              this.useCaptcha = true;
              this.disableSubmit = false;
              return;
            }
            if(typeof this.$refs.captcha !== 'undefined'){
              this.$refs.captcha.reset();
            }
            throw new Error("Invalid captcha");
          }
          if (typeof response.data.token === "undefined") {
            throw new Error("Bad response");
          }
          this.$cookies.set("chat_settings", response.data);
          this.disableSubmit = false;
          if(typeof this.$refs.captcha !== 'undefined'){
            this.$refs.captcha.reset();
          }
          this.$emit("beginChat", response.data);
        })
        .catch(e => {
          console.error(e);
          this.disableSubmit = false;
          this.$emit("errorChat", e.message + ". Please try again.");
        });
    }
  },
  data: function() {
    return {
      userName: "",
      userEmail: "",
      userPhone: "",
      additionalFields: {},
      submit: false,
      disableSubmit: false,
      useCaptcha: false,
      captchaToken: "",
      catpchaEKey: "",
    };
  },
  computed: {
    checkDisableButton() {
      return this.disableSubmit || this.useCaptcha && this.captchaToken === "" && this.windowSettings.captcha_sitekey
    },
    userNameInvalid() {
      return this.$v.userName != undefined && this.$v.userName.$invalid;
    },
    emailInvalid() {
      return this.$v.userEmail != undefined && this.$v.userEmail.$invalid;
    },
    phoneInvalid() {
      return this.$v.userPhone != undefined && this.$v.userPhone.$invalid;
    },
    rules() {
      var rules = {};
      if (this.windowSettings.name_required) {
        rules["userName"] = {
          required,
          minLength: minLength(2)
        };
      }
      if (this.windowSettings.email_required) {
        rules["userEmail"] = {
          required,
          email
        };
      }
      if (this.windowSettings.phone_required) {
        rules["userPhone"] = {
          required,
          phoneNumber
        };
      }
      rules["additionalFields"] = {};
      if (this.loginFormSettings.additionalFields != undefined && this.loginFormSettings.additionalFields.length) {
        this.loginFormSettings.additionalFields.forEach(element => {
          if (
            element.hasOwnProperty("required") != undefined &&
            element.required
          ) {
            rules["additionalFields"][element.name] = {
              required
            };
          } else {
            rules["additionalFields"][element.name] = {};
          }
        });
      }
      // console.log(rules);
      return rules;
    }
  },
  validations() {
    return this.rules;
  },
  watch: {
    windowSettings(newVal){
      if(newVal.use_captcha === "always"){
        this.useCaptcha = true;
      }
    }
  },
  mounted() {
    if(this.windowSettings.use_captcha === "always"){
      this.useCaptcha = true;
    }
    // console.log(this.loginFormSettings);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-form {
  height: calc(100% - 90px);
  padding: 1rem 1.5rem;
  overflow: hidden;
}
.centerCaptcha {
  width: 305px;
  margin: auto;
}
</style>