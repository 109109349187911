import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import feather from 'vue-icon'
import BlockUI from 'vue-blockui'
import Vue2TouchEvents from 'vue2-touch-events'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import linkify from 'vue-linkify'

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(VueCookies)
Vue.use(feather, 'v-feather-icon')
Vue.use(BlockUI)
Vue.use(Vue2TouchEvents)
Vue.use(SlimDialog)
Vue.use(PerfectScrollbar)
Vue.directive('linkified', linkify)

Vue.prototype.$generateUUID = function() {
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
    c
  ) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

var app = new Vue({
  render: h => h(App),
}).$mount('#app')
