
<template>
  <div class="default_black_color">
    <ddmessagebox :type="message.sender_type" :icon="message.sender_image">
      <template v-slot:default>
        <ddTypingAnimation v-if="message.typing"/>
        <div
          v-if="!message.typing"
          v-bind:class="{
            'message-content': true,
            'typing': message.typing,
          }"
          class="message-content"
          :data-message-type="message.message_type"
        >
          {{ message.content }}
        </div>
      </template>
      <template v-slot:sender>
        <ddchatmessagesender
          :type="message.sender_type"
          :sender_name="message.sender_name"
          :timestamp="message.timestamp"
        />
      </template>
    </ddmessagebox>
  </div>
</template>

<script>
// import { mapGetters, mapMutations } from "vuex";
import ddmessagebox from "@citrustelecom/dd-interface-design-vuejs/src/components/ddstyle/ddmessagebox.vue";
import ddchatmessagesender from "@citrustelecom/dd-interface-design-vuejs/src/components/ddstyle/ddchatmessagesender.vue";
import ddTypingAnimation from "@citrustelecom/dd-interface-design-vuejs/src/components/ddstyle/ddTypingAnimation.vue";

export default {
  name: "MessageRender",
  components: {
    ddmessagebox,
    ddchatmessagesender,
    ddTypingAnimation,
  },
  props: {
    message: {
      type: Object,
    },
  },
  data: function () {
    return {
      message2: this.message,
    };
  },
  watch: {
    // message: function (newVal, oldVal) {
    //   if (newVal.type == "message") {
    //     if (newVal.sender_name !== oldVal.sender_name) {
    //       this.message2 = newVal;
    //     }
    //     //type message don't change over time, at least they should not...
    //     return;
    //   }
    //   if (newVal.type == "typing") {
    //     this.message2 = newVal;
    //     return;
    //   }
    // },
  },

  //   beforeUpdate(){
  //       console.log('beforeUpdate', this.message.id);
  //   },
  //   updated() {
  //     console.log("xupdated", this.message.id);
  //   },
  //   mounted() {
  //     console.log("xmounted", this.message.id);
  //   },
};
</script>

<style scoped>
.default_black_color {
  color: black;
}

.message-content[data-message-type="agent_whisper"],
.message-content[data-message-type="whisper"] {
  color: #e35666;
}

.message-content.typing {
  font-style: italic;
}
</style>