<template>
  <div
    class="header-container"
    :style="headerColors"
    @click.prevent="onShow"
    :class="{'closed': this.closed}"
  >
    <slot
      name="header"
      :colors="colors"
      :chatTitle="chatTitle"
      :participants="participants"
      :myself="myself"
    ></slot>

    <div v-if="!hasHeaderSlot" class="header-title">
      <p class="header-title-text">
        {{chatTitle}}
        <span
          style="font-weight: bold;"
          v-for="(participant, index) in others"
          :key="participant.id"
        >{{participant.name}}{{others.length - index - 1 ? ', ' : ''}}
        </span>
      </p>
      <!--p class="header-paticipants-text">
        <span></span>
        <span
          v-for="(participant, index) in participants"
          :key="participant.id"
        >{{participant.name}}{{participants.length - index - 1 ? ', ' : ''}}</span>
      </p-->
    </div>

    <div class="header-buttons">
      <div v-show="!windowShown" class="header-show header-button">
        <slot name="show-button" :onShow="onShow">
          <a
            class="header-show-button"
            href="#"
            :style="{fontSize: closeButtonIconSize}"
            @click.prevent="onShow"
          >
            <i class="fas fa-chevron-up"></i>
          </a>
        </slot>
      </div>

      <div v-show="windowShown" class="header-minimise header-button">
        <slot name="minimise-button" :onClose="onClose">
          <a
            class="header-minimise-button"
            href="#"
            :style="{fontSize: closeButtonIconSize}"
            @click.prevent.stop="onMinimise"
          >
            <i class="fas fa-chevron-down"></i>
          </a>
        </slot>
      </div>

      <div v-show="windowShown && chatSettings != null" class="header-exit header-button">
        <slot name="close-button" :closeQuestion="closeQuestion">
          <a
            class="header-exit-button"
            href="#"
            :style="{fontSize: closeButtonIconSize}"
            @click.prevent.stop="closeQuestion"
          >
            <i class="fas fa-times"></i>
          </a>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
/*
 * TODO: improve support for more than 10 participants (the names list may break in some cases)
 */
export default {
  props: {
    colors: {
      type: Object,
      required: true
    },
    borderStyle: {
      type: Object,
      required: false,
      default: () => {
        return {
          topLeft: "10px",
          topRight: "10px",
          bottomLeft: "10px",
          bottomRight: "10px"
        };
      }
    },
    chatSettings: {
      type: Object,
      required: false
    },
    closed: {
      type: Boolean,
      required: false,
      default: false
    },
    hideShowButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false
    },
    closeButtonIconSize: {
      type: String,
      required: false,
      default: "15px"
    },
    windowShown: {
      type: Boolean,
      default: false
    },
    onShow: {
      type: Function,
      required: false,
      default: () => false
    },
    onMinimise: {
      type: Function,
      required: false,
      default: () => false
    },
    onClose: {
      type: Function,
      required: false,
      default: () => false
    },
    closeQuestion: {
      type: Function,
      required: false,
      default: () => false
    },
    windowSettings: {
      type: Object,
      required: false
    }
  },
  computed: {
    participants() {
      return this.$store.state.participants;
    },
    others() {
      return this.participants.filter(object => object.id != this.myself.id);
    },
    myself() {
      return this.$store.state.myself;
    },
    chatTitle() {
      return this.$store.state.chatTitle;
    },
    hasHeaderSlot() {
      return !!this.$slots["header"];
    },
    headerColors() {
      return {
        "--header-bg-color": this.colors.header.bg,
        "--header-text-color": this.colors.header.text,
        "--header-closed-bg-color": this.colors.header_closed.bg,
        "--header-closed-text-color": this.colors.header_closed.text
      };
    }
  },
  methods: {},
  watch: {
    windowShown(newVal, oldVal) {
      this.windowShown = newVal;
    },
    windowSettings(newVal, oldVal) {
      this.windowSettings = newVal;
      // console.log(this.windowSettings);
    }
    // colors: {
    //   handler(newVal, oldVal) {
    //     this.colors = newVal;
    //     console.log(this.colors);
    //   },
    //   immediate: true
    // }
  },
  mounted() {
    // console.log(this.windowSettings);
  }
};
</script>

<style lang="less">
.header-container {
  height: 62px;
  display: flex;
  padding: 0 20px 0 10px;
  align-items: center;
  background-color: var(--header-bg-color);
  //   -webkit-box-shadow: 0 2px 20px 2px rgba(90, 90, 90, 0.47);
  //   box-shadow: 0 2px 20px 2px rgba(90, 90, 90, 0.47);
  z-index: 5;

  .header-title {
    padding: 6px;
    flex: 1;
    text-align: left;
  }

  .header-title-text {
    margin-bottom: 0;
    color: var(--header-text-color);
  }

  .header-paticipants-text {
    color: #e4e4e4;
    font-size: 12px;
    margin-top: 5px;
    max-height: 30px;
    overflow: hidden;
  }

  .header-button {
    display: inline-block;
  }

  .header-show-button,
  .header-exit-button,
  .header-minimise-button {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    margin-left: 10px;
  }

  .icon-close-chat {
    color: #fff;
    width: 100%;
  }

  .icon-close-chat:hover {
    color: var(--header-bg-color);
  }
}
</style>
