<template>
  <!-- <section class="section login-form"> -->
  <div>
    <div class="reopen-button-area">
      <vue-hcaptcha ref="captcha" v-if="useCaptcha && windowSettings.captcha_sitekey" :sitekey="windowSettings.captcha_sitekey" @verify="captchaSolved" @expired="captchaInvalid" @reset="captchaInvalid"></vue-hcaptcha>
    </div>
    <div class="field submit-button">
      <div class="control">
        <button
          v-if="!checkDisableButton"
          type="submit"
          :disabled="checkDisableButton"
          class="button is-success is-fullwidth"
          v-on:click="beginChat"
        >
          Open chat
        </button>
      </div>
    </div>
  </div>
  <!-- </section> -->
</template>

<script>
import axios from "axios";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: "AutoLoginForm",
  components: {
    VueHcaptcha
  },
  props: {
    windowShown: {
      type: Boolean,
      default: false,
    },
    loginFormSettings: {
      type: Object,
      required: true,
      default: function () {
        return {
          loginMsg: "Enter your details to proceed",
          yourName: "Your name",
          yourEmail: "E-mail",
          startChat: "Click to begin chat",
          additionalFields: {},
        };
      },
    },
    windowSettings: {
      type: Object,
      required: true,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    captchaSolved: function(token, eKey) {
      this.captchaToken = token;
      this.catpchaEKey = eKey;
      this.beginChat();
    },
    captchaInvalid: function(){
      this.captchaToken = "";
      this.catpchaEKey = "";
    },
    beginChat: function (event) {
      if (this.disableSubmit) {
        return;
      }

      this.disableSubmit = true;

      this.$emit("showOverlay", this.loginFormSettings.connectingMsg);
      axios
        .post(
          this.windowSettings.token_url,
          {
            customer_name: this.userName,
            customer_email: this.userEmail,
            customer_phone: this.userPhone,
            additional_fields: this.additionalFields,
            chat_token: this.windowSettings.chat_token,
            captcha_token: this.captchaToken,
          },
          {
            timeout: 10000,
          }
        )
        .then((response) => {
          // console.log(response);
          if (
            typeof response.data.error !== "undefined" &&
            response.data.error === "_InvalidCatchaToken"
          ) {
            this.windowSettings.captcha_sitekey = response.data.sitekey;
            if (!this.useCaptcha){
              this.useCaptcha = true;
              this.disableSubmit = false;
              return;
            }
            if(typeof this.$refs.captcha !== 'undefined'){
              this.$refs.captcha.reset();
            }
            throw new Error("Invalid captcha");
          }
          if (typeof response.data.token === "undefined") {
            throw new Error("Bad response");
          }
          this.$cookies.set("chat_settings", response.data);
          this.$emit("beginChat", response.data);
          this.disableSubmit = false;
        })
        .catch((e) => {
          console.error(e);
          this.disableSubmit = false;
          this.$emit("errorChat", e.message + ". Please try again.");
        });
    },
  },
  data: function () {
    return {
      userName: "Customer",
      userEmail: "random@digideskchat.localhost",
      userPhone: "07777777777",
      disableSubmit: false,
      useCaptcha: false,
      captchaToken: "",
      catpchaEKey: "",
    };
  },
  watch: {
    windowShown(newVal, oldVal) {
      if (newVal) {
        if(this.useCaptcha){
          // this.$refs.captcha.execute();
        }else{
          this.beginChat();
        }
      }
      // this.windowShown = newVal;
    },
    windowSettings(newVal){
      if(newVal.use_captcha === "always"){
        this.useCaptcha = true;
      }
    }
  },
  computed: {
    checkDisableButton(){
      return this.disableSubmit || this.useCaptcha && this.captchaToken === "" && this.windowSettings.captcha_sitekey
    },
  },
  mounted() {
    if(this.windowSettings.use_captcha === "always"){
      this.useCaptcha = true;
    }
    // if (this.windowShown) {
    //   this.beginChat();
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reopen-button-area {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 305px;
}
</style>