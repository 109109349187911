<template>
  <div
    id="app"
    @click="setLastActivity"
    @keydown="setLastActivity"
    @mousemove="setLastActivity"
    v-touch="setLastActivity"
  >
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <Header
      :window-settings="windowSettings"
      :window-shown="windowShown"
      :chat-settings="chatSettings"
      :colors="colors"
      :border-style="borderStyle"
      :closed="closed"
      :hide-close-button="hideCloseButton"
      :close-button-icon-size="closeButtonIconSize"
      :on-minimise="onMinimise"
      :on-close="onClose"
      :close-question="closeQuestion"
      :on-show="onShow"
      v-if="headerShown"
    >
      <template #show-button>
        <slot name="show-button"></slot>
      </template>
      <template #close-button>
        <slot name="close-button"></slot>
      </template>
      <template #header>
        <slot name="header"></slot>
      </template>
    </Header>

    <OohForm
      v-show="chatSettings == null && windowSettings.ooh_webform_show"
      :ooh-form-settings="oohFormSettings"
      v-on:beginChat="beginChat"
      v-on:errorChat="errorChat"
      v-on:showOverlay="showOverlay"
      v-on:hideOverlay="hideOverlay"
      :window-settings="windowSettings"
    />

    <AutoLoginForm
      :window-shown="windowShown"
      v-if="this.windowSettings.skip_chat_input_form && chatSettings == null && !windowSettings.ooh_webform_show && !this.closed"
      :login-form-settings="loginFormSettings"
      v-on:beginChat="beginChat"
      v-on:errorChat="errorChat"
      v-on:showOverlay="showOverlay"
      :window-settings="windowSettings"
    />


    <LoginForm
      v-if="!this.windowSettings.skip_chat_input_form"
      v-show="chatSettings == null && !windowSettings.ooh_webform_show"
      :login-form-settings="loginFormSettings"
      v-on:beginChat="beginChat"
      v-on:errorChat="errorChat"
      v-on:showOverlay="showOverlay"
      :window-settings="windowSettings"
    />

    <Chat
      v-show="chatSettings != null"
      :chat-settings="chatSettings"
      :window-settings="windowSettings"
      :participants="participants"
      :myself="myself"
      :messages="messages"
      :input-visible="inputVisible"
      :on-type="startTyping"
      :on-message-submit="onMessageSubmit"
      :chat-title="chatTitle"
      :placeholder="placeholder"
      :colors="colors"
      :border-style="borderStyle"
      :on-close="onClose"
      :hide-close-button="hideCloseButton"
      :close-button-icon-size="closeButtonIconSize"
      :submit-icon-size="submitIconSize"
      :load-more-messages="toLoad.length > 0 ? loadMoreMessages : null"
      :async-mode="asyncMode"
    />
    <div v-on:click="hideOverlay()">
      <BlockUI
        :message="overlayMsg"
        :url="overlayUrl"
        v-show="overlayMsg != null  && windowShown"
        v-bind:class="{ 'above-dialog': this.overlayAboveDialog }"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Header from "@/components/Header.vue";
import OohForm from "@/components/OohForm";
import LoginForm from "@/components/LoginForm.vue";
import AutoLoginForm from "@/components/AutoLoginForm.vue";
import Chat from "@/components/Chat.vue";
import moment from "moment";
import { mapMutations } from "vuex";
import store from "./store";
import "./../node_modules/bulma/css/bulma.css";
import "./../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "./../node_modules/@fortawesome/fontawesome-free/js/all.js";
import styles from "./assets/custom.css";

export default {
  name: "app",
  components: {
    Header,
    OohForm,
    LoginForm,
    AutoLoginForm,
    Chat
  },
  props: {
    hideCloseButton: {
      type: Boolean,
      required: false,
      default: true
    },
    closeButtonIconSize: {
      type: String,
      required: false,
      default: "20px"
    }
  },
  data: function() {
    return {
      overlayTimeout: null,
      overlayTimeoutDuration: null,
      origin: null,
      referrer: null,
      windowSettings: {
        write_your_name_here: "Your name",
        name_required: 1,
        write_your_email_here: "Your e-mail",
        email_required: 1
      },
      chatSettings: null,
      loginFormSettings: {},
      oohFormSettings: {},
      headerShown: false,
      windowShown: false,
      webchatId: null,
      wsUrl: null,
      ws: null,
      wsReconnect: true,
      inputVisible: true,
      status: null,
      closed: false,
      closedByAgent: false,
      typing: false,
      typingTime: null,
      typingTextTime: null,
      lastActivity: null,
      lastActivityTimeout: null,
      msgText: "",
      overlayMsg: null,
      overlayAboveDialog: true,
      overlayUrl: "./loading.gif",
      visible: true,
      participants: [],
      myself: {},
      messages: [],
      toLoad: [],
      chatTitle: "Chat",
      placeholder: "Send your message",
      colors: {
        header: {
          bg: "#63c900",
          text: "#fff"
        },
        header_closed: {
          bg: "#e35666",
          text: "#fff"
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#bdb8b8"
          },
          others: {
            bg: "#fb4141",
            text: "#fff"
          },
          messagesDisplay: {
            //bg: 'rgb(247, 243, 243)',
            //bg: '#f7f3f3'
            bg: "#f7f3f3"
          }
        },
        submitIcon: "#b91010"
      },
      borderStyle: {
        topLeft: "0px",
        topRight: "0px",
        bottomLeft: "0px",
        bottomRight: "0px"
      },
      submitIconSize: "20px",
      setQueueMessageOnOverlayTimer: false,
      asyncMode: true
    };
  },
  computed: {
    isWebSocketActive: function() {
      return this.ws != null && this.ws.readyState === WebSocket.OPEN;
    }
    // closed: function() {
    //   return this.chatSettings != null && this.windowShown;
    // }
  },
  methods: {
    ...mapMutations([
      "setParticipants",
      "setMyself",
      "setMessages",
      "setPlaceholder",
      "setChatTitle",
      "newMessage",
      "deleteMessage",
      "clearMessages"
    ]),
    hideOverlay() {
      if (this.overlayTimeoutDuration === null) {
        return; //only allow to hide overlays with timeouts
      }
      this.overlayMsg = null;
    },
    switchOverlayText(fromText, toText) {
      if (this.overlayMsg === fromText) {
        this.overlayMsg = toText;
      }
    },
    cancelQueueMessageOnOverlay() {
      if (this.setQueueMessageOnOverlayTimer) {
        clearTimeout(this.setQueueMessageOnOverlayTimer);
      }
    },
    setQueueMessageOnOverlay(title, content, delay /* in sec */) {
      /**
       * It shows the message after specified delay, to avoid flickering
       */
      if (typeof delay === "undefined") {
        delay = 0;
      }

      this.cancelQueueMessageOnOverlay();

      if (delay === 0) {
        this.setChatTitle(title);
        this.showOverlay(content);
        return;
      }

      this.setQueueMessageOnOverlayTimer = setTimeout(() => {
        this.setChatTitle(title);
        this.showOverlay(content);
      }, delay * 1000);
    },
    showOverlay(text, timeout = null, icon = null) {
      switch (icon) {
        case "error":
          this.overlayUrl = "./error.png";
          break;
        default:
          this.overlayUrl = "./loading.gif";
          break;
      }
      this.overlayMsg = text;
      clearTimeout(this.overlayTimeout);
      this.cancelQueueMessageOnOverlay();
      this.overlayTimeoutDuration = timeout;
      if (timeout != null) {
        this.overlayTimeout = setTimeout(this.showOverlay, timeout);
      }
    },
    setLastActivity() {
      var date = new Date();
      this.lastActivity = date.toISOString();
      // console.log("setLastActivity");
    },
    sendLastActivity() {
      if (this.isWebSocketActive && this.inputVisible) {
        // console.log("sendLastActivity");
        this.ws.send(
          JSON.stringify({
            type: "webchat_status",
            data: {
              status: "user_activity",
              time: this.lastActivity
            }
          })
        );
      }
      clearTimeout(this.lastActivityTimeout);
      this.lastActivityTimeout = setTimeout(this.sendLastActivity, 5000);
    },
    sendOpenActivity(){
      if(typeof this.windowSettings.visit_activity_update_url === 'undefined' || !this.windowSettings.visit_activity_update_url){
        return; //no url to send open activity
      }
      console.log('sendOpenActivity', this.windowSettings.visit_activity_update_url, this.chatSettings.token);

      axios
        .post(
          this.windowSettings.visit_activity_update_url,
          {
            timestamp: moment(),
            token: this.chatSettings.token,
          },
          {
            withCredentials: true,
            timeout: 10000
          }
        )
        .then(response => {
          console.log('sendOpenActivity resp', response);
        })
        .catch(e => {
          console.error(e);
        });
    },
    connect() {
      this.sendOpenActivity();
      this.ws = new WebSocket(this.wsUrl);
      var vue = this;
      this.ws.onopen = e => {
        this.wsReconnect = true;
        // console.log("WebSocket opened");
        this.showOverlay(null);
        this.sendLastActivity();
      };
      this.ws.onmessage = function(e) {
        // console.log("Message: " + e.data);
        vue.handleData(e.data);
      };
      this.ws.onclose = e => {
        if (this.wsReconnect) {
          this.showOverlay("Reconnecting...");
          console.info(
            "Socket is closed. Reconnect will be attempted in 1 second."
          );
          var app = this;
          setTimeout(function() {
            app.connect();
          }, 1000);
        } else if (this.overlayTimeoutDuration == null) {
          this.showOverlay(null);
        }
      };
      this.ws.onerror = function(err) {
        console.error("Socket encountered error. Closing socket...");
        this.ws.close();
      };
    },
    postMessage(data) {
      if (this.referrer == null || this.referrer.length == 0) {
        return;
      }
      parent.postMessage(data, this.referrer);
    },
    beginChat(chatSettings = null) {
      this.closedByAgent = false;
      this.inputVisible = true;
      if (chatSettings != null) {
        this.chatSettings = chatSettings;
      }
      if (this.chatSettings != null) {
        this.setChatTitle(this.windowSettings.connecting_title);
        this.showOverlay("Connecting...");

        // Create WebSocket
        this.wsUrl =
          this.windowSettings.websocket_url + this.chatSettings.token;
        this.connect();
      }
    },
    errorChat(message) {
      this.showOverlay(message, 3000, "error");
    },
    resetTimer() {
      clearTimeout(this.typingTime);
      this.typingTime = setTimeout(
        this.stopTyping,
        this.windowSettings.typing_timeout
      );
    },
    inactivityTime() {
      window.onload = this.resetTimer;
      // DOM Events
      document.onkeydown = this.resetTimer;
    },
    startTyping(e) {
      this.msgText = e.target.innerText;
      if (this.typing) {
        return;
      }
      // console.log("Start typing");
      this.typingTextTime = setTimeout(this.sendTypedText, 500);
      if (this.isWebSocketActive) {
        this.typing = true;
        this.ws.send(JSON.stringify(this.getTypingMsg(true)));
      }
    },
    stopTyping() {
      if (!this.typing) {
        return;
      }
      // console.log("Stop typing");
      clearTimeout(this.typingTextTime);
      if (this.isWebSocketActive) {
        this.ws.send(JSON.stringify(this.getTypingMsg(false)));
      }
      this.typing = false;
    },
    getTypingMsg(action) {
      var date = new Date();
      let msgText = this.msgText;
      if(msgText.indexOf('!') === 0){
          msgText = " "+msgText;
      }

      return {
        type: "typing",
        timestamp: date.toISOString(),
        chat_id: this.webchatId,
        typing: action,
        content: msgText
      };
    },
    sendTypedText() {
      if (this.isWebSocketActive) {
        // console.log("Typing in progress");
        this.ws.send(JSON.stringify(this.getTypingMsg(this.typing)));
      }
      this.typingTextTime = setTimeout(this.sendTypedText, 500);
    },
    loadMoreMessages(resolve) {
      setTimeout(() => {
        resolve(this.toLoad);
        // Make sure the loaded messages are also added to our local messages copy or they will be lost
        this.messages.unshift(...this.toLoad);
        this.toLoad = [];
      }, 1000);
    },
    onMessageSubmit(message) {
      // console.log("Message submit");
      this.msgText = "";
      this.stopTyping();
      if(message.content.indexOf('!') === 0){
          message.content = " "+message.content;
      }

      this.addMessage(message, false, false);
      this.ws.send(
        JSON.stringify({
          type: "message",
          typing: false,
          timestamp: moment(),
          message: message,
          chat_id: this.webchatId
        })
      );
      // axios
      //   .post(
      //     this.$postUrl,
      //     {
      //       timestamp: moment(),
      //       token: this.chatSettings.token,
      //       message: message,
      //       webchat_id: this.webchatId
      //     },
      //     {
      //       timeout: 2000
      //     }
      //   )
      //   .then(response => {
      //     // console.log(response);
      //     message.uploaded = true;
      //   })
      //   .catch(e => {
      //     console.log(e);
      //   });
    },
    onShow() {
      if (this.windowShown) {
        return;
      }
      this.windowShown = true;
      this.postMessage("maximise");
    },
    onMinimise() {
      var vue = this;
      this.postMessage("minimise");
      vue.windowShown = false;
    },
    closeQuestion() {
      this.closed = true;
      this.overlayAboveDialog = false;
      this.$dialogs
        .confirm("Are you sure you want to close chat?", {
          title: "Confirm",
          cancelLabel: "No",
          okLabel: "Yes"
        })
        .then(res => {
          this.closed = false;
          this.overlayAboveDialog = true;
          if (res.ok) {
            var vue = this;
            this.status = "closed";
            vue.onClose();
          }
        });
    },
    onClose(closedByAgent = false) {
      var vue = this;
      var token = vue.chatSettings.token;

      vue.wsReconnect = false;
      vue.showOverlay(null);

      // Wait for window hide
      var delay = closedByAgent ? 0 : 1000;
      // setTimeout(function() {
      vue.windowShown = true;

      // Remove chat settings
      vue.$cookies.remove("chat_settings");
      vue.chatSettings = null;

      // Change chat header
      vue.setChatTitle(vue.windowSettings.click_for_webchat);
      vue.participants = [];
      vue.setParticipants(vue.participants);

      // Clear messages
      vue.clearMessages();

      if (!this.closedByAgent) {
        this.transcriptDialog(token);
      }

      if (this.isWebSocketActive) {
        vue.ws.send(
          JSON.stringify({
            type: "close",
            status: this.status,
            closed_by_user: true
          })
        );

        // Close websocket
        vue.ws.close();
        vue.ws = null;
      }
    },
    transcriptDialog(token) {
      this.closed = true;
      this.overlayAboveDialog = true;
      const options = {
        title: false,
        cancelLabel:
          this.windowSettings.download_transcript_on == "1"
            ? this.windowSettings.download_transcript
            : false,
        okLabel: "OK"
      };
      this.$dialogs
        .confirm(this.windowSettings.chat_session_closed_title, options)
        .then(res => {
          this.closed = false;
          if (res.ok === false) {
            this.downloadTranscript(token);
          }
        });
    },
    downloadTranscript(token) {
      window.location.href = this.windowSettings.transcript_url + token;
    },
    addMessage(element, uploaded = true, viewed = true) {
      var participantId = element.user_id;
      var myself = false;
      var content = element.content;
      var typing = false;

      if (this.myself.id == element.user_id) {
        myself = true;
      }
      switch (element.type) {
        case "message":
          break;
        case "typing":
          element.id = participantId + "_typing";
          typing = element.typing;
          break;
        default:
          return;
      }
      var message = {
        content: content,
        typing: typing,
        myself: myself,
        participantId: participantId,
        timestamp: moment(element.timestamp),
        uploaded: uploaded,
        viewed: viewed,
        sender_type: element.sender_type,
        message_type: element.message_type,
        id: element.id ? element.id : this.$generateUUID()
      };

      if (element.type == "message") {
        if (element.hasOwnProperty("old_id")) {
          this.removeMessage(element.old_id);
        }
        if(message.content.indexOf('!') !== 0){
          this.newMessage(message);
        }
      } else if (element.user_id != this.myself.id) {
        if (message.typing) {
          if(message.content.indexOf('!') !== 0){
            this.newMessage(message);
          }else{
            this.removeMessage(message.id);
          }
        } else {
          this.removeMessage(message.id);
        }
      }
    },
    removeMessage(id) {
      this.deleteMessage(id);
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    addParticipant(name, id, image = null) {
      let participant = {
        name: name,
        id: id,
        image: image
      };
      this.participants.push(participant);
    },
    handleData(data) {
      switch (typeof data) {
        case "string":
          try {
            data = JSON.parse(data);
          } catch (error) {
            console.warn("Invalid message");
            return;
          }
          break;
        case "object":
          break;
        default:
          console.warn("Invalid message");
          return;
      }
      if (!data.hasOwnProperty("type")) {
        return;
      }
      console.log("Received data: " + data.type);
      console.log(data);

      // Handle data type
      var msgData = data.data;
      switch (data.type) {
        case "user_data":
          // User info
          this.myself.id = msgData.user_id;
          break;
          // case "chat.on.typing":
          // case "chat.ws.typing":
          //   var action = data.action;
          //   if (action) {
          //     this.addMessage(data);
          //   } else {
          //     this.removeMessage(data.user.id + "_typing");
          //   }
          break;
        case "chat.on.chat_info":
          // console.log("chat_info");
          // Header change
          this.setChatTitle(this.windowSettings.title);

          // Chat info
          this.webchatId = msgData.chat_id;

          // Clear participants
          this.participants = [];
          this.setParticipants(this.participants);

          data.data.user.forEach(user => {
            // console.log(element);
            var userName = user.name;
            var userId = user.id;
            // Myself?
            if (user.id == this.myself.id) {
              this.myself = {
                name: userName,
                id: userId,
                image: null
              };
            }
            this.addParticipant(userName, userId, user.image);
          });

          // this.showOverlay(null);
          break;
        case "chat.on.messages":
          msgData.message.forEach(element => {
            this.addMessage(element);
          });
          break;
        case "chat.on.message":
          msgData.message.forEach(element => {
            this.addMessage(element);
            if (
              element.user_id != this.myself.id &&
              element.type == "message"
            ) {
              if(element.content.indexOf('!') !== 0){
                this.playSound(
                  "sounds/" +
                    this.windowSettings.notification_sound_frontend +
                    ".mp3"
                );
              }
            }
          });
          break;
        case "chat.on.chat_status":
          this.status = msgData.status;
          var reason = null;
          switch (this.status) {
            case "waiting":
              this.setQueueMessageOnOverlay(
                "Connecting",
                "Connecting...",
                0 // the amount of time wait before changing to avoid flickering
              );
              return;
            case "in_queue":
            case "rejected":
              this.setQueueMessageOnOverlay(
                this.windowSettings.queue_title,
                this.windowSettings.queue_message,
                2 // the amount of time wait before changing to avoid flickering
              );
              return;
            case "assigned":
              this.setQueueMessageOnOverlay(
                this.windowSettings.connecting_title,
                this.windowSettings.connecting_message,
                2 // the amount of time wait before changing to avoid flickering
              );
              return;
            case "accepted":
              this.cancelQueueMessageOnOverlay();
              this.showOverlay(null);
              return;
            case "closed":
            case "invalid":
              break;
            case "missed":
              reason = msgData.reason;
              if (reason == null) {
                reason = this.windowSettings.webchat_queue_calls;
              }
              break;
            case "abandoned":
            case "answered":
              reason = this.windowSettings.chat_session_expired_message;
              break;
          }
          // Already closed?
          if (this.closedByAgent) {
            return;
          }
          this.closedByAgent = true;
          // Close if there's no messages
          if (this.$store.state.messages == 0) {
            this.onClose();
            this.showOverlay(reason, 15000, "error");
            return;
          }
          // Display info dialog
          this.transcriptDialog(this.chatSettings.token);
          // Hide input field
          this.inputVisible = false;
          this.showOverlay(reason, 15000, "error");
          break;
      }
    }
  },
  beforeCreate() {
    // console.log("beforeCreate");
    this.$store = store();
  },
  created() {
    // console.log("created");
  },
  beforeMount() {
    // console.log("beforeMount");
  },
  mounted() {
    // console.log("mounted");
    this.referrer = document.referrer;
    if (this.referrer.length == 0) {
      this.headerShown = true;
      this.windowShown = true;
      // return;
    }

    window.addEventListener(
      "message",
      event => {
        // if (event.origin != "http://localhost") {
        //   return;
        // }
        if (event.data == "setActivity") {
          this.setLastActivity();
          return;
        }
        if (event.data == "maximise") {
          this.onShow();
          return;
        }
        if (event.data == "minimise") {
          this.onMinimise();
          return;
        }

        if (typeof event.data.connecting_message === "undefined") {
          // not a settings message
          return;
        }

        this.origin = event.origin;
        this.headerShown = true;
        this.windowSettings = event.data;
        var additionalFields = {};
        try {
          additionalFields = JSON.parse(this.windowSettings.additional_fields);
        } catch (e) {
          additionalFields = {};
        }
        this.loginFormSettings = {
          loginMsg: this.windowSettings.enter_your_details_to_proceed || "Enter your details to proceed",
          yourName: this.windowSettings.write_your_name_here,
          yourEmail: this.windowSettings.write_your_email_here,
          yourPhone: this.windowSettings.write_your_phone_here,
          startChat: this.windowSettings.start_chat,
          connectingMsg: this.connecting_message,
          additionalFields: additionalFields
        };
        this.oohFormSettings = {
          msg: this.windowSettings.webchat_unavailable,
          iframe: this.windowSettings.webchat_unavailable_iframe || false,
          oohform: this.windowSettings.webchat_unavailable_oohform || false
        };
        this.colors.header.bg = this.windowSettings.heading_colour;
        this.colors.header.text = this.windowSettings.text_colour;
        this.colors.header_closed.bg = this.windowSettings.closed_heading_colour;
        this.colors.header_closed.text = this.windowSettings.closed_text_colour;

        this.setChatTitle(this.windowSettings.click_for_webchat);
        this.setPlaceholder(this.windowSettings.start_typing);
        this.beginChat();
        this.inactivityTime();
        this.postMessage("ready");

        // Restore existing chat
        var chatSettings = this.$cookies.get("chat_settings");
        if (chatSettings != null) {
          this.onShow();
          this.beginChat(chatSettings);
        }
      },
      false
    );
  }
};
</script>

<style>
html,
body,
#app {
  height: 100%;
}

html,
body {
  overflow: hidden;
}

.header-container {
  position: relative;
  z-index: 10000;
}

/* Dialog style */
.v-dialog {
  z-index: 9998 !important;
  font-weight: normal !important;
}

.loading-container {
  z-index: 9997 !important;
}

.loading-container.above-dialog {
  z-index: 9999 !important;
}

.v-dialog-title {
  font-weight: bold !important;
}

/* .v-dialog-container .v-dialog-btn {
  font-weight: bold !important;
} */
</style>
